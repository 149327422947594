import axios from 'axios';
import getQueryParamsFromObject from 'helpers/general';

const BASE_URL = `https://api.trustcirclereferral.com/occupations`;

export const getOccupations = (params) => {
  const queryParams = getQueryParamsFromObject(params);
  return axios.get(`${BASE_URL}?${queryParams}`);
};

export const getCategoriesByOccupation = (id) =>
  axios.get(`${BASE_URL}/${id}/categories`);
