import { combineReducers } from 'redux';
import authUser from './auth/reducer';
import language from './language/reducer';
import menu from './menu/reducer';
import occupation from './occupation/reducer';
import settings from './settings/reducer';

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  language,
  occupation,
});

export default reducers;
