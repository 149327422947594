const getQueryParamsFromObject = (data) => {
  const filteredEntries = Object.entries(data).filter(([, value]) => !!value);

  const queryString = filteredEntries
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&');

  return queryString;
};

export default getQueryParamsFromObject;
