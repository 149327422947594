export const SET_OCCUPATIONS = '[OCCUPATION] SET';
export const FETCHING_OCCUPATIONS = '[OCCUPATION] FETCHING';
export const SET_OCCUPATIONS_ERROR = '[OCCUPATION] SET_ERROR';
export const FETCH_OCCUPATIONS = '[OCCUPATION] FETCH';
export const SET_OCCUPATION_CATEGORIES = '[OCCUPATION] SET_CATEGORIES';
export const FETCHING_OCCUPATION_CATEGORIES =
  '[OCCUPATION] FETCHING_CATEGORIES';
export const SET_OCCUPATION_CATEGORIES_ERROR =
  '[OCCUPATION] SET_CATEGORIES_ERROR';
export const FETCH_OCCUPATION_CATEGORIES = '[OCCUPATION] FETCH_CATEGORIES';

export const setOccupations = (occupations) => ({
  type: SET_OCCUPATIONS,
  payload: { occupations },
});

export const fetchOccupations = () => ({
  type: FETCH_OCCUPATIONS,
});

export const setFetchingOccupations = () => ({
  type: FETCHING_OCCUPATIONS,
});

export const setOccupationsError = (error) => ({
  type: SET_OCCUPATIONS_ERROR,
  payload: { error },
});

export const fetchOccupationCategories = () => ({
  type: FETCHING_OCCUPATION_CATEGORIES,
});

export const setOccupationCategories = (categories) => ({
  type: SET_OCCUPATIONS,
  payload: { categories },
});

export const setFetchingOccupationCategories = () => ({
  type: FETCHING_OCCUPATION_CATEGORIES,
});

export const setOccupationCategoriesError = (error) => ({
  type: SET_OCCUPATION_CATEGORIES_ERROR,
  payload: { error },
});
