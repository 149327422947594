import {
  FETCHING_LANGUAGES,
  SET_LANGUAGES,
  SET_LANGUAGES_ERROR,
} from './actions';

const INIT_STATE = {
  languages: [],
  isLoading: true,
  error: undefined,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_LANGUAGES:
      return {
        ...state,
        isLoading: false,
        error: '',
        languages: action.payload.languages,
      };
    case SET_LANGUAGES_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case FETCHING_LANGUAGES:
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    default:
      return { ...state };
  }
};
