export const SET_LANGUAGES = '[LANGUAGE] SET';
export const FETCHING_LANGUAGES = '[LANGUAGE] FETCHING';
export const SET_LANGUAGES_ERROR = '[LANGUAGE] SET_ERROR';
export const FETCH_LANGUAGES = '[LANGUAGE] FETCH';

export const setLanguages = (languages) => ({
  type: SET_LANGUAGES,
  payload: { languages },
});

export const setFetchingLanguages = () => ({
  type: FETCHING_LANGUAGES,
});

export const setLanguagesError = (error) => ({
  type: SET_LANGUAGES_ERROR,
  payload: { error },
});

export const fetchLanguages = () => ({
  type: FETCH_LANGUAGES,
});
