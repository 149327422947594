import { StatusCodes } from 'http-status-codes';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import getLanguagesApi from '../../api/language.api';
import {
  FETCH_LANGUAGES,
  setFetchingLanguages,
  setLanguages,
  setLanguagesError,
} from './actions';

const getLanguagesAsync = async () =>
  // eslint-disable-next-line no-return-await
  await getLanguagesApi()
    .then((response) => response)
    .catch((error) => error);

function* getLanguages() {
  yield put(setFetchingLanguages());
  try {
    const response = yield call(getLanguagesAsync);
    if (response.status === StatusCodes.OK) {
      yield put(setLanguages(response.data.result));
    }
  } catch (error) {
    yield put(setLanguagesError(error));
  }
}

export function* watchFetchLanguages() {
  yield takeEvery(FETCH_LANGUAGES, getLanguages);
}

export default function* rootSaga() {
  yield all([fork(watchFetchLanguages)]);
}
