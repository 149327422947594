import {
  SET_OCCUPATIONS,
  SET_OCCUPATIONS_ERROR,
  SET_OCCUPATION_CATEGORIES_ERROR,
  SET_OCCUPATION_CATEGORIES,
  FETCHING_OCCUPATIONS,
  FETCHING_OCCUPATION_CATEGORIES,
} from './actions';

const INIT_STATE = {
  occupations: [],
  isFetchingOccupations: true,
  occupationError: undefined,
  occupationCategories: [],
  isFetchingOccupationCategories: true,
  occupationCategoriesError: undefined,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_OCCUPATIONS:
      return {
        ...state,
        isFetchingOccupations: false,
        error: undefined,
        occupations: action.payload.occupations,
      };
    case SET_OCCUPATIONS_ERROR:
      return {
        ...state,
        isFetchingOccupations: false,
        occupationError: action.payload.error,
      };
    case FETCHING_OCCUPATIONS:
      return {
        ...state,
        isFetchingOccupations: true,
        occupationError: undefined,
      };
    case SET_OCCUPATION_CATEGORIES:
      return {
        ...state,
        isFetchingOccupationCategories: false,
        occupationCategoriesError: undefined,
        occupationCategories: action.payload.occupationCategories,
      };
    case SET_OCCUPATION_CATEGORIES_ERROR:
      return {
        ...state,
        isFetchingOccupationCategories: false,
        occupationCategoriesError: action.payload.error,
      };
    case FETCHING_OCCUPATION_CATEGORIES:
      return {
        ...state,
        isFetchingOccupationCategories: true,
        occupationCategoriesError: undefined,
      };
    default:
      return { ...state };
  }
};
