import { StatusCodes } from 'http-status-codes';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { getOccupations as getOccupationsApi } from '../../api/occupation.api';
import {
  FETCH_OCCUPATIONS,
  setFetchingOccupations,
  setOccupations,
  setOccupationsError,
} from './actions';

const getOccupationsAsync = async () =>
  // eslint-disable-next-line no-return-await
  await getOccupationsApi({ fetchAll: true })
    .then((response) => response)
    .catch((error) => error);

function* getOccupations() {
  yield put(setFetchingOccupations());
  try {
    const response = yield call(getOccupationsAsync);
    if (response.status === StatusCodes.OK) {
      yield put(setOccupations(response.data.result));
    }
  } catch (error) {
    yield put(setOccupationsError(error));
  }
}

export function* watchFetchOccupations() {
  yield takeEvery(FETCH_OCCUPATIONS, getOccupations);
}

export default function* rootSaga() {
  yield all([fork(watchFetchOccupations)]);
}
